import React from 'react'
import { dwarvesData } from "../../../util/whelpsData"
import DwarfItem from './DwarfItem'

export default function Dwarves() {
  return (
    <div className='flex flex-col text-white font-reggae'>
      <div className='flex flex-1'>
        <img src='/dwarves/header_pc.png' className='md:block hidden' />
        <img src='/dwarves/header_ph.png' className='md:hidden block' />
      </div>
      <div className='bg-gradient-to-b from-brown-100 md:from-brown-light to-brown-dark pt-12 pb-24'>
        <p className='text-center text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl'>MEET THE DWARVES!</p>
        <div className='grid md:grid-cols-2 px-4 sm:px-8 xl:px-16 2xl:px-24 py-24 gap-x-32 gap-y-16'>
          {dwarvesData.map((dwarf, idx) => (
            <DwarfItem data={dwarf} key={idx} className={idx % 2 === 1 && "flex-row-reverse md:flex-row"} />
          ))}
        </div>
        <div className='flex flex-col gap-4 px-8 sm:px-10 md:px-20 xl:px-32 2xl:px-40'>
          <p className='uppercase text-4xl text-center'>4,444 Polygon Dwarves</p>
          <p className='text-center text-base sm:text-xl xl:text-2xl'>The oft-strange, but ever hard-working inhabitants of the Mystic Mountains, are on their way to join the Whelps universe! With amazing art by our very own Yantri and multiple rarity classes, each with their own unique functions, Dwarves allow you to participate in our staking mini-games for Dragon Tokens and $WHELP. We've even heard rumors the Old Woman and Dwarven King make an appearance and bring with them an extremely valuable perk! With a limited supply, once they are gone - they are gone forever !</p>
        </div>
      </div>
    </div>)
}
