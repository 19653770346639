// Core React
import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Dwarves from "../components/partials/Dwarves"

// Render
const DwarvesPage = () => (
  <Layout
    title="Dwarves || WhelpS NFT"
    contentClassName="px-0 max-w-full bg-secondary-whelps"
    page="dwarves"
  >
    <Dwarves />
  </Layout>
)
export default DwarvesPage
