import React from 'react'

const DwarfItem = (props) => {
  const { data, className } = props
  return (
    <div className={`${className} flex items-center gap-2 2xl:gap-4`}>
      <img className='w-40 xl:w-48 2xl:w-56' src={data.img} alt='' />
      <div className='flex flex-col gap-2'>
        <p className='text-2xl xl:text-3xl 2xl:text-4xl'>{data.name}</p>
        <p className='text-xs xl:text-sm 2xl:text-base'>{data.info}</p>
        <div className='flex gap-4 items-center'>
          <div className={`w-4 h-4 md:w-6 md:h-6 2xl:w-8 2xl:h-8 bg-${data.color}`}></div>
          <p className={`text-${data.color} text-xl xl:text-2xl 2xl:text-3xl`}>{data.detail}</p>
        </div>
      </div>
    </div>
  )
}

export default DwarfItem